export enum AnalyticsEvent {
  AppLaunched = 'app-launched',

  ProjectCreated = 'project-created',
  ProjectCreationErrorHappen = 'project-creation-error-happen',

  ProjectOpened = 'project-opened',
  ProjectOpeningErrorHappen = 'project-opening-error-happen',

  TransitionCreated = 'transition-created',
  TransitionCreationErrorHappen = 'transition-creation-error-happen',

  AuthenticationSessionRequested = 'authentication-session-requested',
  AuthenticationSessionCompleted = 'authentication-session-completed',
  AuthenticationSessionCancelled = 'authentication-session-cancelled',

  ExportPopupViewed = 'export-popup-viewed',

  RenderStartedWithMultiplePresets = 'render-started-with-multiple-presets',
  RenderStarted = 'render-started',
  RenderFinished = 'render-finished',
  RenderFilesSuccessfullyDownloaded = 'render-files-successfully-downloaded',
  RenderErrorHappen = 'render-error-happen',
  RenderCancelled = 'render-cancelled',

  LinkToAnimationInspectorCopied = 'link-to-animation-inspector-copied',
  EmbedCodeOfAnimationInspectorCopied = 'embed-code-of-animation-inspector-copied',

  CheckoutSessionRequested = 'checkout-session-requested',

  AnimationPresetsClicked = 'animation-presets-clicked',
  AnimationPresetsViewed = 'animation-presets-viewed',
  AnimationPresetClicked = 'animation-preset-clicked',
  AnimationPresetApplied = 'animation-preset-applied',

  // @NOTE: default curve style appled (ease, ease-in etc)
  CurveStyleApplied = 'curve-style-applied',
  CurveStyleAttached = 'curve-style-attached',
  CurveStyleDetached = 'curve-style-detached',

  HotkeyPressed = 'hotkey-pressed',

  PluginCrashed = 'plugin-crashed',

  PaywallViewed = 'paywall-viewed',
  PaywallUpgradeClicked = 'paywall-upgrade-clicked',

  PaywallModalViewed = 'paywall-modal-viewed',
  PaywallModalUpgradeClicked = 'paywall-modal-upgrade-clicked',

  // prototype
  PrototypeExportStarted = 'prototype-export-started',
  PrototypeExportedSuccessfully = 'prototype-exported-successfully',
  PrototypeExportedWithError = 'prototype-exported-with-error',

  // team
  CreateNewTeamPageViewed = 'create-new-team-page-viewed',
  TeamCreated = 'team-created',

  // New events
  FrameSelected = 'frame-selected',
  OpenInEditorClick = 'open-in-editor-click',
  CopyLinkClick = 'copy-link-click',
  CreatePrototypeClick = 'create-prototype-click',
  CreateProjectClick = 'create-project-click',
  SyncProjectClick = 'sync-project-click',
  DeleteProjectsClick = 'delete-projects-click',
  ProjectCreateClick = 'project-create-click',
  ProjectCreationFailed = 'project-creation-failed',
  FolderCreate = 'folder-create',
  FolderMove = 'folder-move',
  FolderRename = 'folder-rename',
  FolderDelete = 'folder-delete',
  FolderOpen = 'folder-open',
  ProjectMove = 'project-move',
  ProjectOpen = 'project-open',
  ProjectTrashed = 'project-trashed',
  DashboardView = 'dashboard-view',
  UpgradeBannerClick = 'upgrade-banner-click',
  InspectView = 'inspect-view',

  // New error events
  PluginProjectCreateError = 'plugin-project-create-error',
  PluginProjectSyncError = 'plugin-project-sync-error',
}
